$simple-bg: rgba(255, 255, 255, 0.8);
$simple-color: #000;
$simple-progressBar: #c7c7c7;
$simple-progressBarPercentage: #4c4c4c;

$success-bg: #4caf50;
$success-color: #c8e6c9;
$success-progressBar: #388e3c;
$success-progressBarPercentage: #81c784;

$info-bg: rgba(255,255,255,0.8);
$info-color: #303A47;
$info-progressBar: #1565c0;
$info-progressBarPercentage: #64b5f6;

$warning-bg: #ff9800;
$warning-color: #fff3e0;
$warning-progressBar: #ef6c00;
$warning-progressBarPercentage: #ffcc80;

$error-bg: #f44336;
$error-color: #ffebee;
$error-progressBar: #c62828;
$error-progressBarPercentage: #ef9a9a;

$async-bg: $info-bg;
$async-color: $info-color;
$async-progressBar: $info-progressBar;
$async-progressBarPercentage: $info-progressBarPercentage;

$confirm-bg: #009688;
$confirm-color: #e0f2f1;
$confirm-progressBar: #4db6ac;
$confirm-progressBarPercentage: #80cbc4;

$prompt-bg: #009688;
$prompt-color: #e0f2f1;

$snotify-title-font-size: 16px !default;
$snotify-body-font-size: auto !default;

@if $snotify-title-font-size == auto {
  $snotify-title-font-size: 1.8em;
}

@if $snotify-body-font-size == auto {
  $snotify-body-font-size: 1em;
}

.snotifyToast {
  display: block;
  cursor: pointer;
  background-color: $simple-bg;
  height: 100%;
  margin: 5px;
  opacity: 0;
  border-radius: 5px;
  overflow: hidden;
  pointer-events: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.302);

  &--in {
    animation-name: appear;
  }

  &--out {
    animation-name: disappear;
  }

  &__close {
    position: absolute;
    top: 7px;
    right: 8px;
    width: 18px;
    height: 18px;
    font: 14px/100% arial, sans-serif;
    cursor: pointer;
    transition: all 100ms ease-out;
    background-color: transparent;
    z-index:2;
  }

  &__inner {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    padding: 17px 21px 17px 21px;
    min-height: 78px;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Lato', sans-serif;
    line-height: 19px;
    color: $simple-color;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 94%;
      top: 16px;
      width: 18px;
      height: 18px;
      display: flex;
      background-color: transparent;
      transform: rotate(45deg);
      transition: all 100ms ease-in;
      z-index:1;
    }
    &:hover:before, &:hover:after {
      transition: all 100ms 100ms ease-out;
    }
    &:before {
      width: 18px;
      height: 2px;
      margin: -1px auto auto -9px;
    }
    &:after {
      width: 2px;
      height: 18px;
      margin: -9px auto auto -1px;
    }

    &:hover:before {
      width: 18px;
      height: 2px;
      margin: -1px auto auto -9px;
      background-color: #707070;
    }
    &:hover:after {
      width: 2px;
      height: 18px;
      margin: -9px auto auto -1px;
      background-color: #707070
    }
  }

  &__progressBar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: $simple-progressBar;

    &__percentage {
      position: absolute;
      top: 0;
      left: 0;
      height: 10px;
      background-color: $simple-progressBarPercentage;
      max-width: 100%;
    }
  }

  &__title {
    font-size: $snotify-title-font-size;
    line-height: 1.2em;
    margin-bottom: 5px;
    font-weight: bold;
    color: #303A47;
    font-family: 'Lato', sans-serif;
    line-height: 19px;
  }

  &__body {
    font-size: $snotify-body-font-size;
  }
}

.snotifyToast-show {
  transform: translate(0, 0);
  opacity: 1;
}

.snotifyToast-remove {
  max-height: 0;
  overflow: hidden;
  transform: translate(0, 50%);
  opacity: 0;
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

/***************
 ** Modifiers **
 **************/

.snotify-simple {
  .snotifyToast__title,
  .snotifyToast__body {
    color: $simple-color;
  }
}

.snotify-success {
  background-color: $success-bg;
  .snotifyToast__progressBar {
    background-color: $success-progressBar;
  }
  .snotifyToast__progressBar__percentage {
    background-color: $success-progressBarPercentage;
  }
  .snotifyToast__body {
    color: $success-color;
  }
}

.snotify-info {
  background-color: $info-bg;
  .snotifyToast__progressBar {
    background-color: $info-progressBar;
  }
  .snotifyToast__progressBar__percentage {
    background-color: $info-progressBarPercentage;
  }
  .snotifyToast__body {
    color: $info-color;
  }
}

.snotify-warning {
  background-color: $warning-bg;
  .snotifyToast__progressBar {
    background-color: $warning-progressBar;
  }
  .snotifyToast__progressBar__percentage {
    background-color: $warning-progressBarPercentage;
  }
  .snotifyToast__body {
    color: $warning-color;
  }
}

.snotify-error {
  background-color: $error-bg;
  .snotifyToast__progressBar {
    background-color: $error-progressBar;
  }
  .snotifyToast__progressBar__percentage {
    background-color: $error-progressBarPercentage;
  }
  .snotifyToast__body {
    color: $error-color;
  }
}

.snotify-async {
  background-color: $async-bg;
  .snotifyToast__progressBar {
    background-color: $async-progressBar;
  }
  .snotifyToast__progressBar__percentage {
    background-color: $async-progressBarPercentage;
  }
  .snotifyToast__body {
    color: $async-color;
  }
}

.snotify-confirm {
  background-color: $confirm-bg;
  .snotifyToast__progressBar {
    background-color: $confirm-progressBar;
  }
  .snotifyToast__progressBar__percentage {
    background-color: $confirm-progressBarPercentage;
  }
  .snotifyToast__body {
    color: $confirm-color;
  }
}

.snotify-prompt {
  background-color: $prompt-bg;
  ng-snotify-prompt {
    width: 100%;
  }
  .snotifyToast__title {
    margin-bottom: 0;
  }
  .snotifyToast__body {
    color: $prompt-color;
  }
}

.snotify-confirm,
.snotify-prompt {
  .snotifyToast__inner {
    padding: 10px 15px;
  }
}
