/* You can add global styles to this file, and also import other style files */

// while theme
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
// dark theme
// @import '@angular/material/prebuilt-themes/pink-bluegrey.css';
// @import '@angular/material/prebuilt-themes/purple-green.css';
@import "~jsoneditor/dist/jsoneditor.min.css";

@import "../../uxshared/styles/material";

html,
body {
  margin: 0;
  padding: 0;

  height: 100%;
}

.vjs-seeking .vjs-loading-spinner,
.vjs-waiting .vjs-loading-spinner {
  display: none;
}


mat-dialog-container.mat-dialog-container {
  padding: 0;
  overflow: visible;
  background: transparent;
  border-radius: 15px;
}

@import '~video.js/dist/video-js.css';

.form{
  &-row{
    display: flex;
  }
  &_list{
    list-style-type: none;
  }
  &-input{
    width: 600px;
    max-width: 800px;
  }
  &__btn{
    width: 200px;
  }
}
